import { render, staticRenderFns } from "./RequestDetails.vue?vue&type=template&id=32b159b5&scoped=true&"
import script from "./RequestDetails.vue?vue&type=script&lang=js&"
export * from "./RequestDetails.vue?vue&type=script&lang=js&"
import style0 from "./RequestDetails.scss?vue&type=style&index=0&id=32b159b5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b159b5",
  null
  
)

export default component.exports