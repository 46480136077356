<template>
  <div class="request-details">
    <div class="container container--small">
      <transition name="page-fade" mode="out-in">
        <div
          v-if="requestHandled < 1"
          class="request-details__frame"
        >
          <div class="request-details__sections">
            <section class="request-section">
              <h3 class="request-section__header">
                {{ $t("author-requests.basicData") }}
              </h3>

              <ul class="request-section__list">
                <li class="request-section__list-row">
                  <p class="request-section__list-label">
                    {{ $t("author-requests.details.fullName") }}
                  </p>
                  <p class="request-section__list-value">
                    {{ details.firstName }} {{ details.lastName }}
                  </p>
                </li>

                <li class="request-section__list-row">
                  <p class="request-section__list-label">
                    {{ $t("author-requests.details.birthDate") }}
                  </p>
                  <p class="request-section__list-value">
                    {{ birthDay }}
                  </p>
                </li>

                <li class="request-section__list-row">
                  <p class="request-section__list-label">
                    {{ $t("author-requests.details.email") }}
                  </p>
                  <p class="request-section__list-value">
                    {{ details.email }}
                  </p>
                </li>

                <li class="request-section__list-row">
                  <p class="request-section__list-label">
                    {{ $t("author-requests.details.city") }}
                  </p>
                  <p class="request-section__list-value">
                    {{ details.city }}
                  </p>
                </li>

                <li class="request-section__list-row">
                  <p class="request-section__list-label">
                    {{ $t("author-requests.details.street") }}
                  </p>
                  <p class="request-section__list-value">
                    {{ details.street }}
                  </p>
                </li>

                <li class="request-section__list-row">
                  <p class="request-section__list-label">
                    {{ $t("author-requests.details.postalCode") }}
                  </p>
                  <p class="request-section__list-value">
                    {{ details.postalCode }}
                  </p>
                </li>

                <li class="request-section__list-row">
                  <p class="request-section__list-label">
                    {{ $t("author-requests.details.country") }}
                  </p>
                  <p class="request-section__list-value">
                    {{ details.country }}
                  </p>
                </li>
              </ul>
            </section>

            <section class="request-section">
              <h3 class="request-section__header">
                {{ $t("author-requests.companyData") }}
              </h3>

              <ul class="request-section__list">
                <li class="request-section__list-row">
                  <p class="request-section__list-label">
                    {{ $t("author-requests.details.companyName") }}
                  </p>
                  <p class="request-section__list-value">
                    {{ details.companyName ? details.companyName : $t('no data') }}
                  </p>
                </li>

                <li class="request-section__list-row">
                  <p class="request-section__list-label">
                    {{ $t("author-requests.details.companyTaxId") }}
                  </p>
                  <p class="request-section__list-value">
                    {{ details.companyTaxId ? details.companyTaxId : $t('no data') }}
                  </p>
                </li>

                <li class="request-section__list-row">
                  <p class="request-section__list-label">
                    {{ $t("author-requests.details.city") }}
                  </p>
                  <p class="request-section__list-value">
                    {{ details.companyCity ? details.companyCity : $t('no data') }}
                  </p>
                </li>

                <li class="request-section__list-row">
                  <p class="request-section__list-label">
                    {{ $t("author-requests.details.street") }}
                  </p>
                  <p class="request-section__list-value">
                    {{ details.companyStreet ? details.companyStreet : $t('no data') }}
                  </p>
                </li>

                <li class="request-section__list-row">
                  <p class="request-section__list-label">
                    {{ $t("author-requests.details.postalCode") }}
                  </p>
                  <p class="request-section__list-value">
                    {{ details.companyPostalCode ? details.companyPostalCode : $t('no data') }}
                  </p>
                </li>

                <li class="request-section__list-row">
                  <p class="request-section__list-label">
                    {{ $t("author-requests.details.country") }}
                  </p>
                  <p class="request-section__list-value">
                    {{ details.companyCountry ? details.companyCountry : $t('no data') }}
                  </p>
                </li>
              </ul>
            </section>

            <section class="request-section request-section--upload">
              <h4 class="request-section__header">
                {{ $t("author-requests.attachedFiles") }}
              </h4>

              <button
                class="request-section__upload"
                @click="showImageModal()"
                v-if="details.documents"
              >
                <img
                  class="request-section__upload-img"
                  :src="imageLink"
                  alt
                />
                <div class="request-upload__footer">
                  <span>{{ $t("author-requests.details.certificate") }}</span>
                </div>
              </button>

              <p class="request-section__no-certificate" v-else>
                {{ $t('no image')}}
              </p>
            </section>
          </div>

          <div class="request-details__actions">
            <base-button
              @click="handleReject()"
              class="request-details__reject"
            >
              <img
                src="/assets/icons/close.svg"
                alt
                class="request-details__reject-button"
              />
              {{ $t("author-requests.reject") }}
            </base-button>
            <base-button
              @click="handleAccept()"
              class="request-details__accept"
              :title="$t('author-requests.accept')"
            >
              {{ $t("author-requests.accept") }}
            </base-button>
          </div>
        </div>

        <!-- Final box notification -->
        <base-notification-box
          :mode="requestState"
          :heading="$t(`author-requests.${requestState}.title`)"
          v-else-if="requestHandled === 1"
        >
          {{ $t(`author-requests.${requestState}.content`) }}
          <template slot="actions">
            <div>
              <base-button
                class="request-details__others"
                to="/requests"
                :title="$t('author-requests.notification.others')"
              >
                {{ $t("author-requests.notification.others") }}
              </base-button>
              <base-button
                class="request-details__panel"
                to="/dashboard"
                :title="$t('author-requests.notification.panel')"
              >
                {{ $t("author-requests.notification.panel") }}
              </base-button>
            </div>
          </template>
        </base-notification-box>

        <div class="request-details__error" v-else-if="requestHandled === 2">
          <h2 class="request-details__error-text">
            {{ $t("author-requests.error") }}
          </h2>
          <div class="request-details__error-actions">
            <base-button
              class="request-details__others"
              to="/requests"
              :title="$t('author-requests.notification.back')"
            >
              {{ $t("author-requests.notification.others") }}
            </base-button>
            <base-button
              class="request-details__panel"
              to="/dashboard"
              :title="$t('author-requests.notification.panel')"
            >
              {{ $t("author-requests.notification.panel") }}
            </base-button>
          </div>
        </div>
      </transition>

      <!-- Reject user modal-->
      <base-modal
        @input="handleRejectCancel()"
        @accept="handleRejectConfirm()"
        :value="true"
        :footer="false"
        size="big"
        v-if="requestHandled === -1"
      >
        <div class="reject-modal" slot="content">
          <h4 class="reject-modal__heading">
            {{ $t("author-requests.modal.title") }}
          </h4>

          <div v-if="details" class="reject-modal__rejection">
            <span>{{ details.firstName }} {{ details.lastName }}</span>
          </div>

          <h4 class="reject-modal__explanation">
            {{ $t("author-requests.modal.explanation") }}
          </h4>

          <base-textarea
            v-model="notes"
            class="reject-modal__area"
            :placeholder="$t('author-requests.modal.placeholder')"
          />

          <div class="reject-modal__actions">
            <base-button
              class="reject-modal__cancel"
              @click="requestHandled = 0"
              :title="$t('author-requests.cancel')"
            >
              {{ $t("author-requests.cancel") }}
            </base-button>
            <base-button
              class="reject-modal__send"
              :title="$t('author-requests.send')"
              @click="handleRejectConfirm()"
            >
              {{ $t("author-requests.send") }}
            </base-button>
          </div>
        </div>
      </base-modal>

      <!-- Certificate image modal -->
      <base-modal
        :frameless="true"
        :footer="false"
        :value="showModal"
        class="image-modal"
        @input="() => (this.showModal = false)"
      >
        <div slot="content">
          <img
            :src="imageLink"
            alt
            class="image-modal__document"
          />
        </div>
      </base-modal>
    </div>
  </div>
</template>

<script>
import admin from '@/api/services/admin';
import dayjs from 'dayjs';
import { getImageLink } from '@/helpers/index';

export default {
  name: 'RequestDetails',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    requestHandled: 0,
    requestState: '',
    notes: '',
    showModal: false,
    details: {},
  }),
  computed: {
    birthDay() {
      return this.details ? dayjs(this.details.birthday).format('DD/MM/YYYY') : '';
    },
    imageLink() {
      return getImageLink(this.details.documents);
    },
  },
  methods: {
    handleReject() {
      this.requestHandled = -1;
    },
    async handleAccept() {
      try {
        await admin.sendInstructorPromotionDecision(this.id, {
          accept: true,
          notes: this.notes,
        });

        this.requestHandled = 1;
        this.requestState = 'success';
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      }
    },
    async handleRejectConfirm() {
      try {
        await admin.sendInstructorPromotionDecision(this.id, {
          accept: false,
          notes: this.notes,
        });

        this.requestHandled = 1;
        this.requestState = 'warning';
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      }
    },
    handleRejectCancel() {
      this.requestHandled = 0;
    },
    showImageModal() {
      this.showModal = true;
    },
    closeImageModal() {
      this.showModal = false;
    },
  },
  async created() {
    this.$store.dispatch('ui/setPreloader', true);

    try {
      const { data } = await admin.getInstructorRequest(this.id);
      this.details = data;
    } catch (err) {
      this.$store.dispatch('notification/spawn', {
        type: 'error',
        title: this.$t('auth.error.title'),
        text: this.$t('auth.error.text'),
      });

      this.requestHandled = 2;
      this.details = {};
    } finally {
      this.$store.dispatch('ui/setPreloader', false);
    }
  },
};
</script>
<style lang="scss" src="./RequestDetails.scss" scoped/>
